import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, ListItem, Modal, ModalRow, ModalSection, Paragraph } from '@gordon/web-ui';
export default function LoadLoadCarrierModal({ onClose, onConfirm = () => { }, onCancel = () => { }, loadCarrierId, itemsScanned, routeName, model, }) {
    const eject = () => {
        onCancel();
        onClose();
    };
    const handleOnConfirm = () => {
        onConfirm();
        onClose();
    };
    return (_jsx(Modal, { title: "Create load carrier", size: "small", onClose: onClose, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: eject, text: "Cancel" }), _jsx(Button, { variant: "primary", text: "Confirm", onClick: () => {
                        handleOnConfirm();
                    } })] }), children: _jsxs(ModalSection, { children: [_jsx(ModalRow, { columns: 1, children: _jsx(ListItem, { heading: "Route name", children: _jsx(Paragraph, { children: routeName }) }) }), _jsx(ModalRow, { columns: 1, children: _jsx(ListItem, { heading: "Model", children: _jsx(Paragraph, { children: model }) }) }), _jsx(ModalRow, { columns: 1, children: _jsx(ListItem, { heading: "Load carrier ID", children: _jsx(Paragraph, { children: loadCarrierId }) }) }), _jsx(ModalRow, { columns: 1, children: _jsx(ListItem, { heading: "Items", children: _jsx(Paragraph, { children: itemsScanned !== null && itemsScanned !== void 0 ? itemsScanned : '0' }) }) })] }) }));
}
