import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import VideoSection from '../components/VideoSection/VideoSection';
import ItemDetailsSection from '../components/ItemDetailsSection/ItemDetailsSection';
import useExternalScanner from '../utils/useExternalScanner';
import PalletDetailsSection from '../components/PalletDetailSection/PalletDetailsSection';
import EmptyDetailsSection from '../components/EmptyDetailsSection/EmptyDetailsSection';
import { Layout } from '../components/Layout/Layout';
import LoadCarrierReturnDetailsSection from '../components/LoadCarrierReturnDetailsSection/LoadCarrierReturnDetailsSection';
export default function GenericItemScanner({ showVideo, scannedItem, routeId, sendScanRequest, actions, }) {
    useExternalScanner(sendScanRequest);
    return (_jsxs(Layout, { showVideo: showVideo, children: [showVideo && _jsx(VideoSection, { onScanResult: sendScanRequest }), !scannedItem && _jsx(EmptyDetailsSection, {}), (scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.scanType) === 'loadCarrier' && (_jsx(LoadCarrierReturnDetailsSection, { scannedLoadCarrier: scannedItem })), (scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.scanType) === 'item' && (_jsx(ItemDetailsSection, { preloadRouteId: routeId, scannedItem: scannedItem, setRouteId: actions.setRouteId })), (scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.scanType) === 'pallet' && (_jsx(PalletDetailsSection, { scannedPallet: scannedItem, preloadRouteId: routeId, setRouteId: actions.setRouteId }))] }));
}
