import { jsx as _jsx } from "react/jsx-runtime";
import { Timeline } from '@gordon/web-ui';
import dayjs from 'dayjs';
import { capitalizeFirstLetter, capitalizeFirstLetterInEachSentence, } from '../../utils/helperFunctions';
const TimeLine = ({ scanHistory, scannedItem, }) => {
    const steps = scanHistory.map((item, index) => {
        var _a;
        const isLastItem = index === scanHistory.length - 1;
        const scanType = capitalizeFirstLetter(`${item.scanType || 'Item'} scan`);
        const depotName = capitalizeFirstLetterInEachSentence(((_a = item.depot) === null || _a === void 0 ? void 0 : _a.name) || '');
        const title = item.status !== 'New'
            ? `${item.status} (${[scanType, depotName].filter(Boolean).join(', ')})`
            : item.status;
        return {
            state: 'done',
            title: title,
            description: dayjs(item.time).format('YYYY-MM-DD, HH:mm'),
            isLastItem: isLastItem,
        };
    });
    if (!scanHistory.some((item) => { var _a; return item.statusCode >= 40 && ((_a = item.depot) === null || _a === void 0 ? void 0 : _a._id) === scannedItem.depotId; })) {
        steps.push({
            state: 'dashed',
            title: `End depot (${capitalizeFirstLetterInEachSentence(scannedItem.depotName || '')})`,
            description: 'The depot that the item will be delivered to',
            isLastItem: true,
        });
    }
    return (_jsx("div", { children: _jsx("div", { children: _jsx(Timeline, { timelineSteps: steps }) }) }));
};
export default TimeLine;
