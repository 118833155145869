import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, IconTrash, Paragraph } from '@gordon/web-ui';
import './ListBlock.scss';
export function ListBlockItem({ label, value, values, color, }) {
    return (_jsxs("div", { className: "wh-app-list-block-item", children: [_jsx(Paragraph, { size: "large", color: "subtle", children: label }), values ? (_jsx("div", { className: "wh-app-list-block-item-values", children: values.map((value, index) => (_jsx(Paragraph, { size: "large", strong: true, color: color, children: value }, index))) })) : (_jsx(Paragraph, { size: "large", strong: true, color: color, children: value }))] }));
}
export function InteractiveListBlockItem({ label, children, onClick, }) {
    return (_jsxs("div", { className: "wh-app-list-block-item", children: [_jsxs("div", { children: [_jsx(Paragraph, { size: "large", color: "subtle", children: label }), children] }), onClick && (_jsx(Button, { text: "delete item", onClick: onClick, icon: IconTrash, iconType: "only", variant: "tertiary" }))] }));
}
export default function ListBlock({ children }) {
    return _jsx("div", { className: "wh-app-list-block", children: children });
}
