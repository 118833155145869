import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import Section from '../Section/Section';
import SectionHeader from '../SectionHeader/SectionHeader';
import Pill, { PillItem } from '../Pill/Pill';
import ListBlock, { InteractiveListBlockItem, ListBlockItem } from '../ListBlock/ListBlock';
import ScanCounter from '../ScanCounter/ScanCounter';
import { IconBox, Paragraph } from '@gordon/web-ui';
export const LoadCarrierSection = ({ eject, carrierTitle, carrierData, showModal, itemInventory, deleteItem, routeName, deliveryDate, }) => {
    var _a, _b, _c;
    const accountNames = Array.from(new Set(itemInventory === null || itemInventory === void 0 ? void 0 : itemInventory.map((item) => item.accountName)));
    return (_jsxs("div", { className: "wh-app-item-details", children: [routeName && (_jsx(ScanCounter, { Icon: _jsx(IconBox, {}), scanProgress: (_a = itemInventory === null || itemInventory === void 0 ? void 0 : itemInventory.length) !== null && _a !== void 0 ? _a : 0, eject: () => {
                    var _a, _b;
                    return showModal('finishLoadCarrier', {
                        itemsScanned: (_a = itemInventory === null || itemInventory === void 0 ? void 0 : itemInventory.length.toString()) !== null && _a !== void 0 ? _a : '0',
                        loadCarrierId: (_b = carrierData === null || carrierData === void 0 ? void 0 : carrierData.scanCode) !== null && _b !== void 0 ? _b : '-',
                        onConfirm: eject,
                    });
                } })), _jsxs(Section, { className: "wh-app-item-details__section", children: [_jsx(SectionHeader, { heading: carrierTitle }), _jsxs(Pill, { children: [_jsx(PillItem, { label: "Status", value: routeName ? 'Loading (Load carrier)' : '-' }), _jsx(PillItem, { label: "Delivery date", value: deliveryDate ? dayjs(deliveryDate).format('ddd, DD MMM') : '-' })] }), _jsxs(ListBlock, { children: [_jsx(ListBlockItem, { label: "Type", value: (_b = carrierData === null || carrierData === void 0 ? void 0 : carrierData.carrierType) !== null && _b !== void 0 ? _b : '-', color: 'subtle' }), _jsx(ListBlockItem, { label: "Carrier ID", color: 'subtle', value: (_c = carrierData === null || carrierData === void 0 ? void 0 : carrierData.scanCode) !== null && _c !== void 0 ? _c : '-' }), _jsx(ListBlockItem, { label: "Accounts", value: accountNames.length > 0 ? accountNames.join(', ') : '-', color: 'subtle' }), _jsx(ListBlockItem, { label: "Route", value: routeName !== null && routeName !== void 0 ? routeName : '-', color: 'subtle' })] }), _jsxs(ListBlock, { children: [_jsx(ListBlockItem, { label: `${itemInventory && itemInventory.length > 0 ? `Items (${itemInventory === null || itemInventory === void 0 ? void 0 : itemInventory.length})` : 'Items'}`, value: '', color: 'subtle' }), itemInventory && itemInventory.length > 0 ? (itemInventory === null || itemInventory === void 0 ? void 0 : itemInventory.map((item) => (_jsx(InteractiveListBlockItem, { label: item.name, onClick: () => {
                                    deleteItem(item._id);
                                }, children: _jsxs(Paragraph, { children: [item.accountName, " \u2022 ", item.weight, " kg"] }) }, item._id)))) : (_jsx(InteractiveListBlockItem, { children: _jsx(Paragraph, { italic: true, children: "Scan items and they will be listed here" }) }))] })] })] }));
};
