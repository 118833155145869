import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paragraph, ModalSection, Modal, ModalRow, ModalColumn, Button } from '@gordon/web-ui';
export default function ConfirmPreloadRouteModal({ onCancel = () => { }, onConfirm = () => { }, onClose = () => { }, scannedItem, }) {
    var _a;
    return (_jsx(Modal, { title: "Start pre-load", size: "small", onClose: () => {
            onCancel();
            onClose();
        }, footer: _jsxs(_Fragment, { children: [_jsx(Button, { variant: "secondary", onClick: () => {
                        onCancel();
                        onClose();
                    }, text: "Cancel" }), _jsx(Button, { variant: "primary", text: "Confirm", onClick: () => {
                        onConfirm();
                        onClose();
                    } })] }), children: _jsxs(ModalSection, { children: [_jsxs(ModalRow, { columns: 2, children: [_jsx(ModalColumn, { children: _jsx(Paragraph, { size: "large", children: "Route:" }) }), _jsx(ModalColumn, { children: _jsx(Paragraph, { size: "large", strong: true, children: (scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.scanType) === 'item'
                                    ? scannedItem.routeName
                                    : (_a = scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.routes[0]) === null || _a === void 0 ? void 0 : _a.name }) })] }), _jsxs(ModalRow, { columns: 2, children: [_jsx(ModalColumn, { children: _jsx(Paragraph, { size: "large", children: "Items:" }) }), _jsx(ModalColumn, { children: _jsx(Paragraph, { size: "large", strong: true, children: scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.totalItems }) })] })] }) }));
}
