import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { MeatballMenu, MenuListItem, IconRoute, IconList, IconWarehouseDepot, } from '@gordon/web-ui';
import Section from '../Section/Section';
import SectionHeader from '../SectionHeader/SectionHeader';
import Pill, { PillItem } from '../Pill/Pill';
import ListBlock, { ListBlockItem } from '../ListBlock/ListBlock';
import './ItemDetailsSection.scss';
import { getStatusColor } from '../../utils/helperFunctions';
import TimeLine from './TimeLine';
import ScanCounter from '../ScanCounter/ScanCounter';
function handleMenuClick(route) {
    window.open(`${process.env.LASTMILE_ENDPOINT}${route}`, '_blank');
}
function MeatballMenuContainer({ routeId, orderId, depotId, }) {
    return (_jsx("div", { className: "wh-app-item-details__meatball-menu", children: _jsxs(MeatballMenu, { variant: "tertiary", children: [_jsx(MenuListItem, { leadingElement: _jsx(IconRoute, {}), text: "Route information", value: "route", disabled: !routeId, onClick: () => handleMenuClick(`/route/${routeId}`) }), _jsx(MenuListItem, { leadingElement: _jsx(IconList, {}), disabled: !orderId, value: "order", text: "Order Details", onClick: () => handleMenuClick(`/order/${orderId}`) }), _jsx(MenuListItem, { leadingElement: _jsx(IconWarehouseDepot, {}), disabled: !depotId, value: "depot", text: "Depot Information", onClick: () => handleMenuClick(`/warehouse/${depotId}`) })] }) }));
}
export default function ItemDetailsSection({ scannedItem, preloadRouteId, setRouteId = () => { }, }) {
    const { orderId, itemName, itemStatus, orderStatus, deliveryDate, orderStatusCode, routeName, depotId, routeId, stopNumber, accountName, } = scannedItem !== null && scannedItem !== void 0 ? scannedItem : {};
    const routeText = (() => {
        if (!orderId)
            return '-';
        if (routeName)
            return routeName;
        return 'No route assigned';
    })();
    return (_jsxs("div", { className: "wh-app-item-details", children: [preloadRouteId && scannedItem && (_jsx(ScanCounter, { scanProgress: scannedItem.scanProgress, totalItems: scannedItem.totalItems, eject: setRouteId })), _jsxs(Section, { className: "wh-app-item-details__section", children: [_jsx(MeatballMenuContainer, { orderId: orderId, routeId: routeId, depotId: depotId }), _jsx(SectionHeader, { heading: itemName || 'Scan QR-code on label' }), _jsxs(Pill, { children: [_jsx(PillItem, { label: "Item Status", value: itemStatus || '-', color: orderId && itemStatus ? 'brand-primary' : undefined }), _jsx(PillItem, { label: "Delivery Date", value: deliveryDate ? dayjs(deliveryDate).format('ddd, DD MMM') : '-' })] }), _jsxs(ListBlock, { children: [_jsx(ListBlockItem, { label: "Route", value: routeText, color: orderId && !routeName ? 'subtle' : undefined }), _jsx(ListBlockItem, { label: "Stop number", value: stopNumber ? stopNumber.toString() : '-' }), _jsx(ListBlockItem, { label: "Order status", value: orderStatus || '-', color: orderStatusCode ? getStatusColor(orderStatusCode) : undefined }), _jsx(ListBlockItem, { label: "Account", value: accountName || '-' })] }), (scannedItem === null || scannedItem === void 0 ? void 0 : scannedItem.scanHistory) && (_jsx(ListBlock, { children: _jsx(TimeLine, { scanHistory: scannedItem.scanHistory, scannedItem: scannedItem }) }))] })] }));
}
