var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import dayjs from 'dayjs';
import { createContext, useCallback, useContext } from 'react';
import { useState, useEffect } from 'react';
export const AuthContext = createContext({
    token: null,
    isLoading: false,
    loginFailed: false,
    isAuthenticated: false,
    login: () => { },
    logout: () => { },
    isTokenExpired: () => true,
    fetchToken: () => __awaiter(void 0, void 0, void 0, function* () { }),
});
const tokenKey = '__wh-token__';
const expiresKey = '__wh-expires__';
export function isTokenExpired() {
    const expiresAt = localStorage.getItem(expiresKey);
    return !expiresAt || dayjs().isAfter(dayjs(expiresAt).subtract(5, 'seconds'));
}
const isSuccessfulLogin = (body) => (body === null || body === void 0 ? void 0 : body.status) === 'OK';
// Custom hook for authentication
export function AuthProvider({ children }) {
    const [token, setToken] = useState(() => localStorage.getItem(tokenKey));
    const [status, setStatus] = useState('idle');
    const login = useCallback(({ email, password }) => {
        var _a;
        setStatus('loading');
        fetch(`${process.env.BACKEND_ENDPOINT}/wh/login`, {
            method: 'POST',
            headers: {
                Authorization: (_a = process.env.WH_AUTHORIZATION) !== null && _a !== void 0 ? _a : '',
                'content-type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({ email, password }),
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((responseBody) => {
            if (isSuccessfulLogin(responseBody)) {
                setStatus('idle');
                localStorage.setItem(expiresKey, responseBody.payload.expires_at);
                localStorage.setItem(tokenKey, responseBody.payload.token);
                setToken(responseBody.payload.token);
            }
            else {
                throw new Error('Login failed');
            }
        })
            .catch(() => {
            logout();
            setStatus('error');
        });
    }, []);
    const fetchToken = () => {
        var _a;
        return fetch(`${process.env.BACKEND_ENDPOINT}/wh/refresh-token`, {
            method: 'GET',
            headers: {
                Authorization: (_a = process.env.WH_AUTHORIZATION) !== null && _a !== void 0 ? _a : '',
                'content-type': 'application/json',
            },
            credentials: 'include',
            mode: 'cors',
        })
            .then((response) => response.json())
            .then((responseBody) => {
            if (isSuccessfulLogin(responseBody)) {
                setStatus('idle');
                localStorage.setItem(expiresKey, responseBody.payload.expires_at);
                localStorage.setItem(tokenKey, responseBody.payload.token);
                setToken(responseBody.payload.token);
            }
            else {
                throw new Error('Refresh token failed');
            }
        })
            .catch(() => {
            logout();
            setStatus('idle');
        });
    };
    const refreshToken = useCallback(() => {
        setStatus('loading');
        fetchToken();
    }, [token]);
    const logout = () => {
        var _a;
        fetch(`${process.env.BACKEND_ENDPOINT}/wh/logout`, {
            method: 'GET',
            headers: {
                Authorization: (_a = process.env.WH_AUTHORIZATION) !== null && _a !== void 0 ? _a : '',
                'content-type': 'application/json',
            },
        }).catch(() => { });
        localStorage.removeItem(expiresKey);
        localStorage.removeItem(tokenKey);
        setToken(null);
    };
    useEffect(() => {
        if (token && isTokenExpired()) {
            refreshToken();
        }
    }, [token]);
    return (_jsx(AuthContext.Provider, { value: {
            token,
            isAuthenticated: !!token,
            login,
            logout,
            isTokenExpired,
            isLoading: status === 'loading',
            loginFailed: status === 'error',
            fetchToken,
        }, children: children }));
}
export function useAuth() {
    return useContext(AuthContext);
}
