import { useFetch } from './useFetch';
export function useFetchCarrierScan(scanCode) {
    const searchParams = scanCode;
    return useFetch(`wh/v2/load-carriers/${searchParams}`, 'GET', {
        triggerOnMount: false,
    });
}
export function useFetchLoadCarrierInventory(scanCode, itemScanCode) {
    return useFetch(`wh/v2/load-carriers/${scanCode}/inventory/${itemScanCode}`, 'GET', {
        triggerOnMount: false,
    });
}
export function useAddItemToCarrier(loadCarrierScanCode) {
    return useFetch(`wh/v2/load-carriers/${loadCarrierScanCode}/addItem`, 'POST', {
        triggerOnMount: false,
    });
}
export function useDeleteItemFromCarrier(loadCarrierScanCode, itemId) {
    return useFetch(`wh/v2/load-carriers/${loadCarrierScanCode}/items/${itemId}`, 'DELETE', {
        triggerOnMount: false,
    });
}
