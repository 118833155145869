var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
const initialState = {
    error: undefined,
    data: undefined,
    isLoading: false,
    isError: false,
    isSuccess: false,
};
export function useFetch(url, method = 'GET', _a = {}) {
    var { triggerOnMount = true } = _a, options = __rest(_a, ["triggerOnMount"]);
    const cancelRequest = useRef(false);
    const [state, setState] = useState(initialState);
    const { fetchToken, isTokenExpired } = useAuth();
    const runRequest = useCallback((body_1, ...args_1) => __awaiter(this, [body_1, ...args_1], void 0, function* (body, requestOptions = {}) {
        var _a, _b, _c, _d, _e;
        // Do nothing if the url is not given
        if (!url)
            return;
        if (isTokenExpired()) {
            yield fetchToken();
        }
        cancelRequest.current = false;
        const token = (_a = localStorage.getItem('__wh-token__')) !== null && _a !== void 0 ? _a : '';
        if (!token) {
            return;
        }
        setState(({ data }) => (Object.assign(Object.assign({}, initialState), { data, isLoading: true })));
        try {
            const response = yield fetch(`${process.env.BACKEND_ENDPOINT}/${url}`, Object.assign(Object.assign(Object.assign({ method }, options), requestOptions), { headers: Object.assign(Object.assign(Object.assign({ Authorization: `Bearer ${token}` }, options.headers), requestOptions.headers), { 'content-type': 'application/json' }), body: body && JSON.stringify(body) }));
            if (!response.ok) {
                if ((_b = response.headers.get('content-type')) === null || _b === void 0 ? void 0 : _b.includes('application/json')) {
                    const error = (yield response.json());
                    throw new Error(error.message);
                }
                throw new Error(response.statusText);
            }
            if (((_c = response.headers.get('content-type')) === null || _c === void 0 ? void 0 : _c.includes('application/pdf ')) ||
                ((_d = response.headers.get('content-type')) === null || _d === void 0 ? void 0 : _d.includes('text/csv'))) {
                const blob = yield response.blob();
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');
                return setState(Object.assign(Object.assign({}, initialState), { isSuccess: true }));
            }
            const data = (yield response.json());
            if (data.status === 'UNAUTHORIZED') {
                return window.location.reload();
            }
            if (data.status !== 'OK') {
                throw new Error(data.message);
            }
            if (cancelRequest.current)
                return;
            setState(Object.assign(Object.assign({}, initialState), { data: (_e = data.data) !== null && _e !== void 0 ? _e : data.payload, isSuccess: true }));
        }
        catch (error) {
            if (cancelRequest.current)
                return;
            setState(Object.assign(Object.assign({}, initialState), { isError: true, error: error }));
        }
    }), [url, method]);
    const setData = useCallback((update) => {
        setState((state) => (Object.assign(Object.assign({}, state), { data: update(state.data) })));
    }, [setState]);
    useEffect(() => {
        if (method === 'GET' && triggerOnMount)
            runRequest();
        return () => {
            cancelRequest.current = true;
        };
    }, [runRequest, method, triggerOnMount]);
    return Object.assign(Object.assign({}, state), { runRequest: runRequest, setData });
}
