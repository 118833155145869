import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ActionsSection from '../components/ActionSection/ActionSection';
import useScanner from '../utils/useScanner';
import useExternalScanner from '../utils/useExternalScanner';
import useActions from '../utils/useActions';
import { Layout } from '../components/Layout/Layout';
import { Select, IconCamera, IconEdit1, IconFlipBackward, IconPrinter, ListItem, Paragraph, Selector, } from '@gordon/web-ui';
import { useDepots } from '../stores/depot';
import GenericItemScanner from './GenericItemScanner';
import LoadCarrierScanner from './LoadCarrierScanner';
const scannerModes = [
    { text: 'Info scan', value: 'info' },
    { text: 'Arrive scan', value: 'arrive' },
    { text: 'Sort scan', value: 'sort' },
    { text: 'pre-load scan', value: 'preload' },
    { text: 'Return scan', value: 'return' },
    { text: 'Return to producer scan', value: 'return-to-producer' },
    { text: 'Load carrier scan', value: 'load-carrier' },
];
export default function PackageScanningPage({ showModal }) {
    const [actions, state] = useActions({ showModal });
    const { showVideo, scannedItem, printLabelOnScan, routeId, undoNextScan, mode } = state;
    const { sendScanRequest, isDestinationDepotEnabled } = useScanner({
        state,
        onSuccessfulScan: actions.onSuccessfulScan,
        onScanError: actions.onScanError,
    });
    useExternalScanner(sendScanRequest);
    const { destinationDepot } = useDepots();
    return (_jsxs(Layout, { showVideo: showVideo, children: [_jsxs(ActionsSection, { showModal: showModal, children: [_jsxs("div", { className: "action-section__actions", children: [_jsx(Select, { disabled: !!routeId, allowUnselect: false, onSelect: actions.setMode, value: mode, options: scannerModes }), _jsxs("div", { className: "action-section__buttons", children: [_jsx(Selector, { text: "Undo next scan", type: "checkbox", size: "small", disabled: !scannedItem && mode === 'info', icon: IconFlipBackward, iconType: "only", checked: undoNextScan, onChange: actions.toggleUndoNextScan }), _jsx(Selector, { text: "Print label", type: "checkbox", size: "small", icon: IconPrinter, disabled: !['sort', 'preload'].includes(mode), iconType: "only", checked: printLabelOnScan, onChange: actions.togglePrintLabel }), _jsx(Selector, { text: "Toggle video", type: "checkbox", size: "small", icon: IconCamera, iconType: "only", checked: showVideo, onChange: actions.toggleVideoThrottle })] })] }), isDestinationDepotEnabled && (_jsx(ListItem, { heading: "End destination", actionIcon: IconEdit1, actionOnClick: () => {
                            showModal('changeDestinationDepot', {
                                isDestination: true,
                            });
                        }, children: _jsx(Paragraph, { color: "brand-primary", children: destinationDepot === null || destinationDepot === void 0 ? void 0 : destinationDepot.name }) }))] }), mode === 'load-carrier' ? (_jsx(LoadCarrierScanner, { showVideo: showVideo, showModal: showModal })) : (_jsx(GenericItemScanner, { actions: actions, routeId: routeId, scannedItem: scannedItem, sendScanRequest: sendScanRequest, showModal: showModal, showVideo: showVideo }))] }));
}
